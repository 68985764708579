<template>
  <div>
    <!-- Card global infos -->
    <b-overlay :show="showLoading" no-wrap />
    <b-card v-if="courtierData">
      <b-card-header class="px-0" v-if="interlocuteurs.interlocuteur">
        <h3 class="text-dark font-weight-bolder">
          <span class="text-lowercase"> {{ $_.get(courtierData, 'denomination_commercial', null) }} </span>
          <b-button v-b-toggle.sidebar-cabinet v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="flat-primary" class="btn-icon rounded-circle">
            <feather-icon icon="Edit3Icon" size="16" />
          </b-button>
          <b-badge variant="light-success" style="font-size:14px;">Formule {{ $_.get(courtierData, 'courtier.abonnement.pack_id', null) }}</b-badge>
        </h3>
        <update-cabinet :personne-morale="courtierData" @record-courtier-returned="recordCourtierReturned" />
      </b-card-header>
      <b-card-text>
        <b-row>
          <b-col sm="12" md="9">
            <b-col sm="12" md="12">
            <!-- <b-row>
              <b-col cols="12" class="pb-2" v-if="$_.get(courtierData, 'denomination_commercial', null)"> Sous la marque - {{ $_.get(courtierData, 'denomination_commercial', null) }} </b-col>
            </b-row> -->
            <b-row class="mt-2" v-if="$_.get(courtierData, 'raison_sociale', null)">
              <b-col md="2" sm="12" class="mb-2 mb-sm-0">
                Raison Sociale :
              </b-col>
              <b-col md="10" sm="12">
                <b-col cols="12">
                  {{ $_.get(courtierData, 'raison_sociale', null) }}
                </b-col>
              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="interlocuteurs.dirigeant">
              <b-col md="2" sm="12" class="mb-2 mb-sm-0">
                Dirigeant :
              </b-col>
              <b-col md="10" sm="12">
                <b-col cols="12">
                    {{ $_.get(interlocuteurs.dirigeant.user.personne_physique, 'civilite', null) + ' ' + $_.get(interlocuteurs.dirigeant.user.personne_physique, 'nom', null) + ' ' + $_.get(interlocuteurs.dirigeant.user.personne_physique, 'prenom', null)  }}
                </b-col>
                <b-col cols="12">
                  {{ $_.get(interlocuteurs.dirigeant.user.personne_physique.moyen_contact, 'tel', null)  }}
                </b-col>
                <b-col cols="12">
                  {{ $_.get(interlocuteurs.dirigeant.user.personne_physique.moyen_contact, 'email', null)  }}
                </b-col>
              </b-col>
            </b-row>
            <b-row class="mt-2" v-if="interlocuteurs.interlocuteur">
              <b-col md="2" sm="12" class="mb-2 mb-sm-0">
                Coordonnées :
              </b-col>
              <b-col md="10" sm="12">
                <b-col cols="12">
                  {{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.libelle', null) ? $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.libelle', null) : '' }}
                  {{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.complement_adresse', null) ? $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.complement_adresse', null) : '' }}
                </b-col>
                <b-col cols="12" v-if="$_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.lieu_dit_ou_bp', null)"> {{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.lieu_dit_ou_bp', null) }} </b-col>
                <b-col sm="6" md="12"> {{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.ville.code_postal', null) + ' - ' + $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.ville.commune', null) }} </b-col>
                <b-col cols="12" v-if="$_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.tel', null)">{{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.tel', null) }} </b-col>
                <b-col cols="12" v-if="$_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.email', null)">{{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.email', null) }} </b-col>
                <b-col cols="12" v-if="$_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.site_web', null)">{{ $_.get(interlocuteurs.interlocuteur.courtier, 'personne_morale.moyen_contact.site_web', null) }} </b-col>
              </b-col>
            </b-row>
            </b-col>
            <b-col sm="12" md="12">
              <!-- <b-row>
                <b-col sm="6" md="12" class="pb-1" v-if="$_.get(courtierData, 'courtier.source', null)"> Raison Sociale : {{ $_.get(courtierData, 'raison_sociale', null) }} </b-col>
                <b-col sm="6" md="12" class="pb-1"> Code Postal - Ville : {{ $_.get(courtierData, 'moyen_contact.adresse', null) + ' - ' + $_.get(courtierData, 'moyen_contact.adresse', null) }} </b-col>
                <b-col cols="12" class="pb-2" v-if="$_.get(courtierData, 'moyen_contact.site_web', null)">  Site web : {{ $_.get(courtierData, 'moyen_contact.site_web', null) }} </b-col>
                <b-col cols="12" class="pb-2" v-if="$_.get(courtierData, 'courtier.sous_statut', null)">  Sous Statut : {{ $_.get(courtierData, 'courtier.sous_statut', null) }} </b-col>
              </b-row> -->
            </b-col>
          </b-col>
          <b-col sm="12" md="3">
            <b-overlay :show="showLoadingForLogo" no-wrap />
            <b-media class="mb-2">
              <template #aside>
                <b-row>
                  <b-col cols="12" class="text-center" style="border:1px solid #ebe9f1;">
                    <b-img :src="logoData.imgBase64" fluid :alt="'Logo ' + $_.get(courtierData, 'raison_sociale', null)" width="auto" height="auto" style="padding: 8px;max-width:200px;" />
                  </b-col>
                  <b-col cols="12" class="p-0">
                    <b-button block class="rounded-0" variant="primary" @click="$refs.refInputEl.click()">
                      <input ref="refInputEl" type="file" class="d-none" @change="onFileChange" />
                      <feather-icon icon="UploadCloudIcon" /> Mettre à jour
                    </b-button>
                  </b-col>
                </b-row>
              </template>
            </b-media>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <!-- Sections cabinet management -->
    <sections-gestion-cabinet v-if="courtierData" :courtier-data="courtierData"
                              :interlocuteurs="interlocuteurs"
                              :correspondant-tracfin="correspondantTracfin"
                              :declarant-tracfin="declarantTracfin" 
                              :dpo="dpo" 
                              @data-code-assureur="dataCodeAssureur"
                              @record-courtier-juridique-returned="RecordCourtierJuridiqueReturned"
                              :code-updated="codeUpdated" />
  </div>
</template>

<script>
import { VBToggle, BRow, BCol, BButton, BCard, BCardText, BCardHeader, BMedia, BImg, BOverlay,BBadge } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import SectionsGestionCabinet from './SectionsGestionCabinet.vue'
import UpdateCabinet from './UpdateCabinet.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    BBadge,

    // UI
    SectionsGestionCabinet,
    UpdateCabinet
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    codeUpdated: {
      type: Boolean,
      default: false,
      required: false
    },
    componentActivated: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: { name: 'Courtiers', courtierId: null },
      required: true
    }
  },
  data() {
    return {
      courtierData: null,
      logoData: {
        imgBase64: null
      },
      inputImageRenderer: null,
      showLoadingForLogo: false,
      showLoading: false,
      interlocuteurs: {},
      correspondantTracfin: {},
      declarantTracfin: {},
      dpo: {},
    }
  },
  watch: {
    componentActivated: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val.courtierId) {
          this.fetchDataCourtier(val.courtierId)
          this.getLogoBase64(val.courtierId)
          this.getInterlocuteurs(val.courtierId)
        }
      }
    }
  },
  methods: {
    dataCodeAssureur(data) {
      this.$emit('data-code-assureur', data)
    },
    fetchDataCourtier(courtierId) {
      this.showLoading = true
      this.$http
        .get(`courtier/fetchDataCourtier/${courtierId}`)
        .then(res => {
          if (res.data.success) {
            if (res.data.data) {
              this.courtierData = res.data.data
              this.$emit('passing-abonnement-courtier-selected', this.courtierData.courtier.abonnement)
              this.showLoading = false
            } else {
              this.courtierData = null
              this.showLoading = false
            }
          }
        })
        .catch(err => {
          this.showLoading = false
          this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
          console.error(err)
        })
    },
    getLogoBase64(courtierId) {
      this.showLoadingForLogo = true
      this.$http
        .get(`courtier/getLogoCourtierBase64/${courtierId}`)
        .then(res => {
          if (res.data.success) {
            if (res.data.data) {
              this.logoData.imgBase64 = res.data.data
              this.showLoadingForLogo = false
            } else {
              this.logoData.imgBase64 = null
              this.showLoadingForLogo = false
            }
          }
        })
        .catch(err => {
          this.showLoadingForLogo = false
          console.error(err)
        })
    },
    getInterlocuteurs(courtierId) {
      this.$http
        .get(`courtier/getInterlocuteursCourtier/${courtierId}`)
        .then(res => {
          if (res.data.success) {
            if (res.data.data) {
              this.interlocuteurs = res.data.data
              this.correspondantTracfin = res.data.data.correspondantTracfin
              this.declarantTracfin = res.data.data.declarantTracfin
              this.dpo = res.data.data.dpo
            }
          }
        })
        .catch(err => {
          console.error(err)
        })
    },
    onFileChange() {
      if (this.$refs.refInputEl.files[0]) {
        this.$swal({
          title: 'Etes-vous sûr de vouloir modifier le logo?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1'
          },
          buttonsStyling: false
        }).then(confirmed => {
          if (confirmed.value) {
            this.showLoadingForLogo = true
            // eslint-disable-next-line prefer-destructuring
            this.inputImageRenderer = this.$refs.refInputEl.files[0]
            if (this.inputImageRenderer) {
              const config = { headers: { 'Content-Type': 'multipart/form-data' } }
              const formData = new FormData()
              formData.append('logo', this.inputImageRenderer)
              formData.append('courtier_id', this.componentActivated.courtierId)

              this.$http
                .post('/courtier/updateLogoCourtier', formData, config)
                .then(res => {
                  if (res.data.success) {
                    this.logoData.imgBase64 = res.data.data
                    this.showLoadingForLogo = false
                  } else {
                    this.logoData.imgBase64 = null
                    this.showLoadingForLogo = false
                  }
                })
                .catch(error => {
                  if (error.response) {
                    this.showLoadingForLogo = false
                    this.messageToast(error.response.data.errors[0], 'Erreur', 'error')
                  } else {
                    this.showLoadingForLogo = false
                    this.messageToast('La ressource demandée ne prend pas en charge le type de support fourni.', 'Erreur', 'error')
                  }
                })
            } else {
              this.showLoadingForLogo = false
              this.messageToast('Logo manquant, veuillez de re-sélectionner le logo que vous souhaitez télécharger.', 'Erreur', 'error')
            }
          }
        })
      }
    },

    // return actions
    RecordCourtierJuridiqueReturned(data, message) {
      this.fetchDataCourtier(this.componentActivated.courtierId)
      this.getInterlocuteurs(this.componentActivated.courtierId)
      this.messageToast(message, 'Succès', 'success')
    },
    recordCourtierReturned(moyenContact, message) {
      this.fetchDataCourtier(this.componentActivated.courtierId)
      this.getInterlocuteurs(this.componentActivated.courtierId)
      this.messageToast(message, 'Succès', 'success')
    }
  },
  setup() {
    return {
      avatarText
    }
  }
}
</script>

<style></style>
