<template>
  <div>
    <b-sidebar id="sidebar-new-code-assureur" ref="sidebarNewCodeAssureur" backdrop bg-variant="white" no-header
               right shadow sidebar-class="sidebar-lg" @hidden="clearData"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">{{ `${dataCodeAssureur && dataCodeAssureur.id  ? 'modifier' : 'nouveau'} code d'assureur` }}</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
        </div>
        <b-overlay :show="showLaoding" rounded="sm">
          <validation-observer ref="newCodeAssureurComposeRules">
            <b-form class="p-2" @reset.prevent="resetForm">
                <h4>INFORMATION</h4>

                <b-form-group label="Assureur *" label-for="assureur">
                  <validation-provider #default="{ errors }" name="assureur" rules="required">
                    <b-form-select v-model="composeData.assureurId" :options="assureursOptions" :state="errors.length > 0 ? false : null"/>
                    <b-form-invalid-feedback :state="errors.length > 0 || errorAssureur ? false : null">{{ errors.length > 0 ? errors[0] : errorAssureur }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Produit(s) *" label-for="produit">
                  <validation-provider #default="{ errors }" name="produit" rules="required">
                    <!-- <b-form-select v-model="composeData.produitId" :options="produitsOptions" :state="errors.length > 0 ? false : null"/> -->
                    <v-select
                      v-model="composeData.produitId"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      multiple
                      label="text"
                      :options="produitsOptions"
                      :state="errors.length > 0 ? false : null"
                    />
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Statut code" label-for="statut-code">
                  <validation-provider #default="{ errors }" name="Statut code">
                    <div class="demo-inline-spacing" id="statut-code">
                      <b-form-radio v-model="composeData.statutCode" name="statut-code-radios"
                        value="NOUVEAU" class="mt-0">
                        NOUVEAU
                      </b-form-radio>
                      <b-form-radio v-model="composeData.statutCode" name="statut-code-radios"
                        value="SYNCHRONISE" class="mt-0">
                        SYNCHRONISÉ
                      </b-form-radio>
                    </div>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Type code" label-for="type-code">
              <validation-provider #default="{ errors }" name="Type code" >
              <div class="demo-inline-spacing" id="type-code">
                <b-form-radio
                  v-model="composeData.typeCode"
                  name="type-code-radios"
                  value="LPDC"
                  class="mt-0"
                >
                  LPC
                </b-form-radio>
                <b-form-radio
                  v-model="composeData.typeCode"
                  name="type-code-radios"
                  value="DIRECT"
                  class="mt-0"
                >
                  DIRECT
                </b-form-radio>
              </div>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

                <b-form-group label="Code direct *" label-for="code-direct" v-if="composeData.typeCode == 'DIRECT' && composeData.assureurId != 2632">
                  <validation-provider #default="{ errors }" name="code direct" rules="required">
                    <b-form-input v-model="composeData.codeDirect" placeholder="Code direct" :state="errors.length > 0 || errorCodeDirect ? false : null"/>
                    <b-form-invalid-feedback :state="errors.length > 0 || errorCodeDirect ? false : null">{{ errors.length > 0 ? errors[0] : errorCodeDirect }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Code lpc *" label-for="code-lpc" v-if="composeData.typeCode == 'LPDC' && composeData.assureurId != 2632">
                  <validation-provider #default="{ errors }" name="code lpc" rules="required">
                    <b-form-input v-model="composeData.codeLpc" placeholder="Code lpc" :state="errors.length > 0 || errorCodeLpc ? false : null"/>
                    <b-form-invalid-feedback :state="errors.length > 0 || errorCodeLpc ? false : null">{{ errors.length > 0 ? errors[0] : errorCodeLpc }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Identifiant WS *" label-for="identifiant_ws" v-if="composeData.typeCode == 'DIRECT' && composeData.assureurId == 2632">
                  <validation-provider #default="{ errors }" name="Identifiant WS" rules="required">
                    <b-form-input v-model="composeData.codeDirect" placeholder="Identifiant WS *" :state="errors.length > 0 || errorCodeDirect ? false : null"/>
                    <b-form-invalid-feedback :state="errors.length > 0 || errorCodeDirect ? false : null">{{ errors.length > 0 ? errors[0] : errorCodeDirect }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Identifiant WS *" label-for="identifiant_ws" v-if="composeData.typeCode == 'LPDC' && composeData.assureurId == 2632">
                  <validation-provider #default="{ errors }" name="code lpc" rules="required">
                    <b-form-input v-model="composeData.codeLpc" placeholder="Identifiant WS *" :state="errors.length > 0 || errorCodeLpc ? false : null"/>
                    <b-form-invalid-feedback :state="errors.length > 0 || errorCodeLpc ? false : null">{{ errors.length > 0 ? errors[0] : errorCodeLpc }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

<!--                <b-form-group label="Date de début *" label-for="date-debut">
                  <validation-provider #default="{ errors }" name="date début" rules="required">
                    <flat-pickr v-model="composeData.dateDebut" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" class="form-control" placeholder="Date début"/>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>-->

<!--                <b-form-group label="Date de fin" label-for="date-fin">
                    <flat-pickr id="date-fin" v-model="composeData.dateFin"
                                :config="configDate" class="form-control" placeholder="Date de fin"/>
                </b-form-group>-->

                <b-form-group label="Identifiant WS *" label-for="identifiant-ws" v-if="composeData.assureurId != 2632">
                  <validation-provider #default="{ errors }" name="identifiant ws" rules="required">
                    <b-form-input v-model="composeData.identifiantWS" placeholder="Identifiant WS" :state="errors.length > 0 ? false : null"/>
                    <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>

                 <b-form-group v-if="composeData.assureurId == 404 || composeData.assureurId == 1 || composeData.assureurId == 905 || composeData.assureurId == 2632" label="Mot de passe WS *" label-for="Mot de passe ws">
                    <validation-provider #default="{ errors }" name="mot de passe ws" rules="required">
                      <b-form-input v-model="composeData.passwordWS" placeholder="Mot de passe WS" :state="errors.length > 0 ? false : null"/>
                      <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                    </validation-provider>
                  </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary"
                            @click="recordDataCodeAssureur"
                  > Enregistrer
                  </b-button>
                </div>
            </b-form>
          </validation-observer>
        </b-overlay>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormRadio,
  BOverlay,
  BSidebar,
  VBToggle,
  VBTooltip,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import {is422} from '@/shared/utils/response'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormSelect,
    BOverlay,
    BFormRadio,
    flatPickr,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    courtierId: {
      type: Number,
      default: null,
      required: false
    },
    dataCodeAssureur: {
      type: Object,
      default: null,
      required: false
    }
  },
  data() {
    return {
      // validation
      required,
      showLaoding: false,
      errorCodeLpc: null,
      errorCodeDirect: null,
      errorAssureur: null,
      // compose data
      composeData: {
        id:null,
        courtierId: null,
        assureurId: null,
        produitId: null,
        codeDirect: null,
        codeLpc: null,
        // dateDebut: null,
        // dateFin: null,
        statut: 'Ouvert',
        typeCode: 'DIRECT',
        statutCode: 'NOUVEAU',
        directLpc: 'DIRECT',
        identifiantWS: null,
        passwordWS: null,
      },

      // Data
      assureursOptions: [{
        value: null,
        text: '-- Choisissez --'
      }],
      produitsOptions: [],
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
    }
  },
  watch: {
    courtierId: {
      handler(val) {
        this.composeData.courtierId = val
      },
      immediate: true
    },
    dataCodeAssureur: {
      handler(val) {
        if(val) {
          this.composeData = val
        }
      },
      deep: true
    },
    'composeData.assureurId': {
      handler(val) {
        
        if(val != null) {
          this.fetchProduitList()
        }
      },
    }
  },
  created() {
    this.fetchAssureurList()
  },
  methods: {
    // Methode additional
    hideSideBare() {
      this.$refs.sidebarNewCodeAssureur.hide()
      this.$refs.newCodeAssureurComposeRules.reset()
    },
    clearData(){
      this.composeData.id = null
      this.composeData.assureurId = null
      this.composeData.produitId = null
      this.composeData.codeDirect = null
      this.composeData.codeLpc    = null
      // this.composeData.dateDebut = null
      // this.composeData.dateFin = null
      this.composeData.identifiantWS = null
      this.composeData.passwordWS = null
      this.composeData.statutCode = 'NOUVEAU'
      this.errorCodeDirect = null
      this.errorCodeLpc = null
      this.errorAssureur = null
    },

    // Fetch data
    fetchAssureurList() {
      this.assureursOptions = [{
        value: null,
        text: '-- Choisissez --',
        type: null
      }]
      this.$http
          .post('/assureurs/fetchAssureurList', { 'isPartenaire': true })
          .then(res => {
            if (res.data.success) {
              res.data.data.forEach(el => {
                this.assureursOptions.push({
                  value: el.id,
                  text: el.nom_com_assureur,
                  type: el.categorie
                })
              })
            }
          })
          .catch(err => {
            this.assureursOptions = [{
              value: null,
              text: '-- Choisissez --',
              type: null
            }]
            console.error(err)
          })
    },
    fetchProduitList(fromChange = false) {
      if(fromChange)
        this.composeData.produitId = null
      this.produitsOptions = []
      this.$http
          .get(`/produit/getAllProduits/${this.composeData.assureurId}`)
          .then(res => {
            if (res.data) {
              res.data.forEach(el => {
                this.produitsOptions.push({
                  value: el.id,
                  text: el.produit_nom
                })

              })
            }
          })
          .catch(err => {
            this.produitsOptions = [{
              value: null,
              text: '-- Choisissez --'
            }]
            console.error(err)
          })
    },
    // Create new code for broker
    recordDataCodeAssureur(){
      this.$refs.newCodeAssureurComposeRules.validate()
          .then(success => {
            if (success) {
              this.showLaoding = true
              this.$http
                  .post('/courtier/recordDataCodeAssureur', this.composeData)
                  .then(res => {
                    if (res.data.success) {
                      this.messageToast(res.data.message, 'Succès', 'success', 'BellIcon')
                      this.showLaoding = false
                      this.clearData()
                      this.hideSideBare()
                      this.$emit('code-assureur-updated')
                    }
                  })
                  .catch(err => {
                    this.showLaoding = false
                    if(is422(err)) {
                      let errorsResponse = err.response.data.errors
                      this.errorCodeLpc     = errorsResponse.codeLpc ? errorsResponse.codeLpc[0][1] : null
                      this.errorCodeDirect  = errorsResponse.codeDirect ? errorsResponse.codeDirect[0][1] : null
                      this.errorAssureur    = errorsResponse.assureurId ? errorsResponse.assureurId[0][0] : null
                    }
                  })
            }
          })
    }
  }
}
</script>

<style scoped>

</style>
