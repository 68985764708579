var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{ref:"sidebarNewCodeAssureur",attrs:{"id":"sidebar-new-code-assureur","backdrop":"","bg-variant":"white","no-header":"","right":"","shadow":"","sidebar-class":"sidebar-lg"},on:{"hidden":_vm.clearData},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0 text-uppercase text-primary font-weight-bolder"},[_vm._v(_vm._s(((_vm.dataCodeAssureur && _vm.dataCodeAssureur.id ? 'modifier' : 'nouveau') + " code d'assureur")))]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('b-overlay',{attrs:{"show":_vm.showLaoding,"rounded":"sm"}},[_c('validation-observer',{ref:"newCodeAssureurComposeRules"},[_c('b-form',{staticClass:"p-2",on:{"reset":function($event){$event.preventDefault();return _vm.resetForm.apply(null, arguments)}}},[_c('h4',[_vm._v("INFORMATION")]),_c('b-form-group',{attrs:{"label":"Assureur *","label-for":"assureur"}},[_c('validation-provider',{attrs:{"name":"assureur","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.assureursOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.assureurId),callback:function ($$v) {_vm.$set(_vm.composeData, "assureurId", $$v)},expression:"composeData.assureurId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 || _vm.errorAssureur ? false : null}},[_vm._v(_vm._s(errors.length > 0 ? errors[0] : _vm.errorAssureur))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Produit(s) *","label-for":"produit"}},[_c('validation-provider',{attrs:{"name":"produit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"multiple":"","label":"text","options":_vm.produitsOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.produitId),callback:function ($$v) {_vm.$set(_vm.composeData, "produitId", $$v)},expression:"composeData.produitId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Statut code","label-for":"statut-code"}},[_c('validation-provider',{attrs:{"name":"Statut code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"demo-inline-spacing",attrs:{"id":"statut-code"}},[_c('b-form-radio',{staticClass:"mt-0",attrs:{"name":"statut-code-radios","value":"NOUVEAU"},model:{value:(_vm.composeData.statutCode),callback:function ($$v) {_vm.$set(_vm.composeData, "statutCode", $$v)},expression:"composeData.statutCode"}},[_vm._v(" NOUVEAU ")]),_c('b-form-radio',{staticClass:"mt-0",attrs:{"name":"statut-code-radios","value":"SYNCHRONISE"},model:{value:(_vm.composeData.statutCode),callback:function ($$v) {_vm.$set(_vm.composeData, "statutCode", $$v)},expression:"composeData.statutCode"}},[_vm._v(" SYNCHRONISÉ ")])],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Type code","label-for":"type-code"}},[_c('validation-provider',{attrs:{"name":"Type code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"demo-inline-spacing",attrs:{"id":"type-code"}},[_c('b-form-radio',{staticClass:"mt-0",attrs:{"name":"type-code-radios","value":"LPDC"},model:{value:(_vm.composeData.typeCode),callback:function ($$v) {_vm.$set(_vm.composeData, "typeCode", $$v)},expression:"composeData.typeCode"}},[_vm._v(" LPC ")]),_c('b-form-radio',{staticClass:"mt-0",attrs:{"name":"type-code-radios","value":"DIRECT"},model:{value:(_vm.composeData.typeCode),callback:function ($$v) {_vm.$set(_vm.composeData, "typeCode", $$v)},expression:"composeData.typeCode"}},[_vm._v(" DIRECT ")])],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0])+" ")])]}}],null,true)})],1),(_vm.composeData.typeCode == 'DIRECT' && _vm.composeData.assureurId != 2632)?_c('b-form-group',{attrs:{"label":"Code direct *","label-for":"code-direct"}},[_c('validation-provider',{attrs:{"name":"code direct","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Code direct","state":errors.length > 0 || _vm.errorCodeDirect ? false : null},model:{value:(_vm.composeData.codeDirect),callback:function ($$v) {_vm.$set(_vm.composeData, "codeDirect", $$v)},expression:"composeData.codeDirect"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 || _vm.errorCodeDirect ? false : null}},[_vm._v(_vm._s(errors.length > 0 ? errors[0] : _vm.errorCodeDirect))])]}}],null,true)})],1):_vm._e(),(_vm.composeData.typeCode == 'LPDC' && _vm.composeData.assureurId != 2632)?_c('b-form-group',{attrs:{"label":"Code lpc *","label-for":"code-lpc"}},[_c('validation-provider',{attrs:{"name":"code lpc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Code lpc","state":errors.length > 0 || _vm.errorCodeLpc ? false : null},model:{value:(_vm.composeData.codeLpc),callback:function ($$v) {_vm.$set(_vm.composeData, "codeLpc", $$v)},expression:"composeData.codeLpc"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 || _vm.errorCodeLpc ? false : null}},[_vm._v(_vm._s(errors.length > 0 ? errors[0] : _vm.errorCodeLpc))])]}}],null,true)})],1):_vm._e(),(_vm.composeData.typeCode == 'DIRECT' && _vm.composeData.assureurId == 2632)?_c('b-form-group',{attrs:{"label":"Identifiant WS *","label-for":"identifiant_ws"}},[_c('validation-provider',{attrs:{"name":"Identifiant WS","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Identifiant WS *","state":errors.length > 0 || _vm.errorCodeDirect ? false : null},model:{value:(_vm.composeData.codeDirect),callback:function ($$v) {_vm.$set(_vm.composeData, "codeDirect", $$v)},expression:"composeData.codeDirect"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 || _vm.errorCodeDirect ? false : null}},[_vm._v(_vm._s(errors.length > 0 ? errors[0] : _vm.errorCodeDirect))])]}}],null,true)})],1):_vm._e(),(_vm.composeData.typeCode == 'LPDC' && _vm.composeData.assureurId == 2632)?_c('b-form-group',{attrs:{"label":"Identifiant WS *","label-for":"identifiant_ws"}},[_c('validation-provider',{attrs:{"name":"code lpc","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Identifiant WS *","state":errors.length > 0 || _vm.errorCodeLpc ? false : null},model:{value:(_vm.composeData.codeLpc),callback:function ($$v) {_vm.$set(_vm.composeData, "codeLpc", $$v)},expression:"composeData.codeLpc"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 || _vm.errorCodeLpc ? false : null}},[_vm._v(_vm._s(errors.length > 0 ? errors[0] : _vm.errorCodeLpc))])]}}],null,true)})],1):_vm._e(),(_vm.composeData.assureurId != 2632)?_c('b-form-group',{attrs:{"label":"Identifiant WS *","label-for":"identifiant-ws"}},[_c('validation-provider',{attrs:{"name":"identifiant ws","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Identifiant WS","state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.identifiantWS),callback:function ($$v) {_vm.$set(_vm.composeData, "identifiantWS", $$v)},expression:"composeData.identifiantWS"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),(_vm.composeData.assureurId == 404 || _vm.composeData.assureurId == 1 || _vm.composeData.assureurId == 905 || _vm.composeData.assureurId == 2632)?_c('b-form-group',{attrs:{"label":"Mot de passe WS *","label-for":"Mot de passe ws"}},[_c('validation-provider',{attrs:{"name":"mot de passe ws","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Mot de passe WS","state":errors.length > 0 ? false : null},model:{value:(_vm.composeData.passwordWS),callback:function ($$v) {_vm.$set(_vm.composeData, "passwordWS", $$v)},expression:"composeData.passwordWS"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary"},on:{"click":_vm.recordDataCodeAssureur}},[_vm._v(" Enregistrer ")])],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }