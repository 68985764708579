<template>
  <div>
    <b-sidebar v-if="personneMorale" id="sidebar-juridique" bg-variant="white" sidebar-class="sidebar-lg" ref="sidebarJuridique" shadow backdrop no-header right>
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Votre structure</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
        </div>
        <validation-observer ref="cabinetJuridiqueComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- clients list -->
            <h4>INFORMATION</h4>
            <b-form-group label="Forme juridique *" label-for="forme_juridique">
              <validation-provider #default="{ errors }" name="forme juridique" rules="required">
                <b-form-input id="forme_juridique" placeholder="Forme juridique" v-model="composeData.courtier.forme_juridique" :class="errors.length > 0 ? 'is-invalid' : ''" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Créée le *" label-for="cree_le">
              <validation-provider #default="{ errors }" name="créée le" rules="required">
                <flat-pickr id="date-creation" v-model="composeData.courtier.date_creation" class="form-control" placeholder="Créée le" :class="errors.length > 0 ? 'is-invalid' : ''" :config="configDate" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Numéro d'orias *" label-for="n_orias">
              <b-form-input id="n_orias" disabled placeholder="Numéro d'orias" v-model="personneMorale.courtier.numero_orias" />
            </b-form-group>

             <b-form-group label="Numéro siren *" label-for="n_siren">
              <b-form-input id="n_siren" disabled placeholder="Numéro siren" v-model="personneMorale.siren" v-mask="'#########'" />
            </b-form-group>

            <b-form-group label="Numéro siret *" label-for="n_siret">
              <validation-provider #default="{ errors }" name="numéro siret" rules="required|integer">
                <b-form-input id="n_siret" placeholder="Numéro siret" v-model="composeData.courtier.siret" :class="errors.length > 0 ? 'is-invalid' : ''" v-mask="'##############'" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Capital social *" label-for="capital_social">
              <validation-provider #default="{ errors }" name="capital social" rules="required|integer">
                <b-form-input id="capital_social" placeholder="Forme juridique" v-model="composeData.courtier.capital_social" :class="errors.length > 0 ? 'is-invalid' : ''" />
              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Effectif *" label-for="effectif">
              <validation-provider #default="{ errors }" name="effectif" rules="required">
                 <b-form-select id="effectif" v-model="composeData.courtier.effectif" :options="Nombre_employes" text-field="text"
                   value-field="value" :class="errors.length > 0 ? 'is-invalid' : ''">
                   <template #first>
                     <b-form-select-option :value="null">Selectionner le nombre d'employés</b-form-select-option>
                   </template>
                 </b-form-select>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          

            <b-form-group label="Êtes-vous membre d'une association d'autorégulation ?">
              <validation-provider rules="required" v-slot="{ errors }" vid="is_anassociation_autoregulation">
                <b-form-radio-group v-model="composeData.isAnAssociationAutoregulation" :options="options.IsHaveAssociationAutoregulation" class="demo-inline-spacing" name="radio-inline" />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <div v-if="composeData.isAnAssociationAutoregulation">
              <validation-provider #default="{ errors }" name="association autorégulation" vid="id_anassociation_autoregulation" rules="required_if:is_anassociation_autoregulation,true">
                <b-form-group label="Association autorégulation" label-for="AssociationAutoregulation">
                  <b-form-select id="AssociationAutoregulation" :state="errors.length > 0 ? false : null" v-model="composeData.IdAssociationAutoregulation" :options="listAssociationAutoregulations" value-field="id" text-field="libelle">
                    <template #first>
                      <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>

              <b-form-group v-if="composeData.IdAssociationAutoregulation === 1" label="Numéro adhérent endya" label-for="numero_adherent_endya">
                <validation-provider v-slot="{ errors }" name="numéro adhérent endya" rules="required_if:id_anassociation_autoregulation,1">
                  <b-form-input id="numero_adherent_endya" placeholder="Numéro adhérent endya" v-model="composeData.NRAssociationAutoregulation" :state="errors.length > 0 ? false : null" />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{ errors[0] }}</b-form-invalid-feedback>
                </validation-provider>
              </b-form-group>
            </div>
            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2" @click="recordCourtier"> Enregistrer </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BFormRadioGroup, BButton, BSidebar, VBToggle, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BFormSelect, BFormSelectOption } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, requiredIf } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import enums from '../../../../../shared/constants/prospect'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BFormSelect,
    BFormRadioGroup,
    BFormSelectOption,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    personneMorale: {
      type: Object,
      default: null,
      required: true
    }
  },
  data() {
    return {
      required,
      requiredIf,
      Nombre_employes : enums.NOMBRE_EMPLOYES,
      configDate: {
        dateFormat: 'd/m/Y',
        allowInput: true,
        onKeyDown: (selectedDates, dateStr, instance, event) => {
          if (event.keyCode != 8) {
            const ele = document.activeElement
            let val = ele.value

            if ((val.length == 2 || val.length == 5) && ele.getAttribute('id')) {
              val += '/'
            }
            ele.value = val
            ele.dispatchEvent(new Event('input'))
          }
        }
      },
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        },
        IsHaveAssociationAutoregulation: [
          { text: 'Oui', value: true },
          { text: 'Non', value: false }
        ]
      },
      villes: [],
      isUpdateAction: false,
      villeOptions: [],
      listAssociationAutoregulations: [],
      composeData: {
        idCourtier: null,
        isAnAssociationAutoregulation: false,
        IdAssociationAutoregulation: null,
        NRAssociationAutoregulation: null,
        courtier: {
          idPersonneMorale: null,
          date_creation: null,
          forme_juridique: null,
          siret: null,
          capital_social: null,
          effectif: null
        }
      }
    }
  },
  watch: {
    'composeData.isAnAssociationAutoregulation': {
      handler(val) {
        if (!val) {
          this.composeData.IdAssociationAutoregulation = null
          this.composeData.NRAssociationAutoregulation = null

        } else {
          this.composeData.IdAssociationAutoregulation = this.personneMorale.courtier.association_autoregulation_id
          this.composeData.NRAssociationAutoregulation = this.personneMorale.courtier.numero_adherent_endya

        }
      }
    },
    personneMorale: {
      immediate: true,
      deep: true,
      handler(val) {
        if (val) {
          this.composeData.idCourtier = val.courtier.id
          this.composeData.isAnAssociationAutoregulation = val.courtier.association_autoregulation_id ? true : false
          this.composeData.IdAssociationAutoregulation = val.courtier.association_autoregulation_id
          this.composeData.NRAssociationAutoregulation = val.courtier.numero_adherent_endya
        }
      }
    }
  },
  created() {
    this.composeData.courtier.idPersonneMorale = this.personneMorale.id
    this.composeData.courtier.date_creation = this.changeFormatDateShort(this.personneMorale.date_creation)
    this.composeData.courtier.forme_juridique = this.personneMorale.forme_juridique
    this.composeData.courtier.siret = this.personneMorale.siret
    this.composeData.courtier.capital_social = this.personneMorale.capital_social
    this.composeData.courtier.effectif = this.personneMorale.effectif
    this.fetchDataAssociationAutoregulation()
  },

  methods: {
    // Methode additional
    hideSideBare() {
      this.$refs.sidebarJuridique.hide()
    },

    clearData() {
      this.composeData.idCourtier = null
      this.composeData.isAnAssociationAutoregulation = false
      this.composeData.IdAssociationAutoregulation = null
      this.composeData.NRAssociationAutoregulation = null

      this.$refs.cabinetJuridiqueComposeRules.reset()
    },
    // fetch data
    fetchDataAssociationAutoregulation() {
      this.$http
        .get('/association_autoregulation/fetchDataAssociationAutoregulation')
        .then(res => {
          if (res.data.success) {
            this.listAssociationAutoregulations = res.data.data
          }
        })
        .catch(err => {
          this.listAssociationAutoregulations = []
          console.error(err)
        })
    },

    // record Data courtier
    recordCourtier() {
      this.$refs.cabinetJuridiqueComposeRules.validate().then(success => {
        if (success) {
          this.$http
            .post('/courtier/updataDataJuridique', this.composeData)
            .then(res => {
              if (res.data.success) {
                this.$emit('record-courtier-juridique-returned', 'Les modifications ont été faites avec succès.')

                this.clearData()
                this.hideSideBare()
              }
            })
            .catch(err => {
              console.error(err)
            })
        }
      })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
