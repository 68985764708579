<template>
  <div>
    <b-row>
      <b-col v-if="courtierData !== null" md="6" sm="12">
        <b-card bg-variant="transparent" body-class="p-2" border-variant="primary" header="Juridique"
                header-bg-variant="primary" header-class="py-0 px-1 font-weight-bolder" header-text-variant="white"
        >
          <template #header>
            <h6 class="mb-0 font-weight-bolder text-white">Votre structure</h6>
            <b-button v-b-toggle.sidebar-juridique v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="btn-icon rounded-circle text-white"
                      variant="flat-success"
            >
              <feather-icon icon="Edit3Icon" size="16"/>
            </b-button>
          </template>
          <b-card-text>
            <!-- <b-list-group>
              <b-list-group-item class="d-flex justify-content-between bg-transparent border-0">
                <span class="mr-1">
                  <feather-icon icon="CircleIcon" size="16"/>
                  <span class="ml-1">{{ $_.get(courtierData, 'forme_juridique', null) }}</span>
                </span>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between bg-transparent border-0">
                <span class="mr-1">
                  <feather-icon icon="CircleIcon" size="16"/>
                  <span class="ml-1">Crée le {{
                      changeFormatDateLong($_.get(courtierData, 'date_creation', null))
                    }}</span>
                </span>
              </b-list-group-item>

              <b-list-group-item class="d-flex justify-content-between bg-transparent border-0">
                <span class="mr-1">
                  <feather-icon icon="CircleIcon" size="16"/>
                  <span class="ml-1">Numéro d'orias {{ $_.get(courtierData.courtier, 'numero_orias', null) }}</span>
                </span>
              </b-list-group-item>

              <b-list-group-item v-if="courtierData.courtier.association_autoregulation_id"
                                 class="d-flex justify-content-between bg-transparent border-0"
              >
                <div class="d-flex bd-highlight">
                  <div class="mr-1 flex-shrink-1 bd-highlight">
                    <feather-icon icon="CircleIcon" size="16"/>
                  </div>
                  <div class="w-100 bd-highlight d-flex flex-column">
                    <div class="bd-highlight">Adhérent à l'association d'autorégulation
                      {{ $_.get(courtierData.courtier.association_autoregulation, 'libelle', null) }} sous numéro
                      {{ $_.get(courtierData.courtier, 'numero_adherent_endya', null) }}
                    </div>
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group> -->
            <b-row>
              <b-col md="12" class="mb-1">
                {{ $_.get(courtierData, 'forme_juridique', null) ? $_.get(courtierData, 'forme_juridique', null) : '' }}
                {{ $_.get(courtierData, 'date_creation', null) ? 'créée le '+ changeFormatDateLong($_.get(courtierData, 'date_creation', null)) : '' }}
              </b-col>
              <b-col md="12" v-if="$_.get(courtierData.courtier, 'numero_orias', null)">
                <b-row>
                  <b-col md="3">
                    N° ORIAS :
                  </b-col>
                  <b-col md="9">
                    {{ $_.get(courtierData.courtier, 'numero_orias', null) }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" v-if="$_.get(courtierData, 'siret', null)">
                <b-row>
                  <b-col md="3">
                    N° SIRET :
                  </b-col>
                  <b-col md="9">
                    {{ $_.get(courtierData, 'siret', null) }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" v-if="$_.get(courtierData, 'capital_social', null)">
                <b-row>
                  <b-col md="3">
                    Capital :
                  </b-col>
                  <b-col md="9">
                    {{ $_.get(courtierData, 'capital_social', null) }}
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" v-if="$_.get(courtierData, 'effectif', null)">
                <b-row>
                  <b-col md="3">
                    Effectif :
                  </b-col>
                  <b-col md="9">
                    {{ $_.get(courtierData, 'effectif', null) }}
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-card-text>
          <update-cabinet-juridique v-if="courtierData" :personne-morale="courtierData"
                                    @record-courtier-juridique-returned="RecordCourtierJuridiqueReturned"
          />
        </b-card>
        <b-card bg-variant="transparent" body-class="p-2" border-variant="primary" header="Interlocuteurs"
            header-bg-variant="primary" header-class="py-0 px-1 font-weight-bolder" header-text-variant="white" v-if="interlocuteurs.interlocuteur" class="mb-0" >
            <template #header>
              <h6 class="mb-0 font-weight-bolder text-white">Vos contacts</h6>
              <b-button v-if="can('Update informations legal broker')"
                class="btn-icon rounded-circle text-white" style="opacity:0" variant="flat-success">
                <feather-icon icon="Edit3Icon" size="16" />
              </b-button>
            </template>
            <b-card-text class="mb-0">
              <b-row class="mb-2">
                <b-col md="3">
                  Administrateur BubbleIn :
                </b-col>
                <b-col md="9">
                  <div v-if="interlocuteurs">
                    {{ checkIsNull(interlocuteurs.interlocuteur.user.personne_physique.civilite, false, '[Civilité]') + ' ' + checkIsNull(interlocuteurs.interlocuteur.user.personne_physique.nom, false, '[Nom]') + ' ' + checkIsNull(interlocuteurs.interlocuteur.user.personne_physique.prenom, false, '[Prénom]')  }}
                    <br>
                    {{ checkIsNull(interlocuteurs.interlocuteur.user.personne_physique.moyen_contact.tel, false, '[Téléphone]')  }}
                    <br>
                    {{ checkIsNull(interlocuteurs.interlocuteur.user.personne_physique.moyen_contact.email, false, '[Mail]')  }}
                  </div>
                  <div v-else v-html="placeHolderRubrique"></div>
                </b-col>
                <!-- <b-col sm="12" md="12">
                <b-row  v-if="interlocuteurs.interlocuteur">
                  <b-col cols="4" class="pb-2">
                    {{ $_.get(interlocuteurs.interlocuteur.user.personne_physique, 'nom', null) + ' ' + $_.get(interlocuteurs.interlocuteur.user.personne_physique, 'prenom', null)  }}
                  </b-col>
                  <b-col cols="8" class="pb-2">
                    {{ $_.get(interlocuteurs.interlocuteur.user.qualification[0], 'fonction', null) }}
                  </b-col>
                  <b-col cols="4" class="pb-2">
                    {{ $_.get(interlocuteurs.interlocuteur.user.personne_physique.moyen_contact, 'tel', null)  }}
                  </b-col>
                  <b-col cols="8" class="pb-2">
                    {{ $_.get(interlocuteurs.interlocuteur.user.personne_physique.moyen_contact, 'email', null)  }}
                  </b-col>
                </b-row>
              </b-col> -->
              </b-row>
              <b-row class="mb-2">
                <b-col md="3">
                  Correspondant Tracfin :
                </b-col>
                <b-col md="9">
                  <div v-if="correspondantTracfin">
                    {{ checkIsNull(correspondantTracfin.user.personne_physique.civilite, false, '[Civilité]') + ' ' +checkIsNull(correspondantTracfin.user.personne_physique.nom, false, '[Nom]') + ' ' + checkIsNull(correspondantTracfin.user.personne_physique.prenom, false, '[Prénom]')  }}
                    <br>
                    {{ checkIsNull(correspondantTracfin.user.personne_physique.moyen_contact.tel, false, '[Téléphone]')  }}
                    <br>
                    {{ checkIsNull(correspondantTracfin.user.personne_physique.moyen_contact.email, false, '[Mail]')  }}
                  </div>
                  <div v-else v-html="placeHolderRubrique"></div>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="3">
                  Déclarant Tracfin :
                </b-col>
                <b-col md="9">
                  <div v-if="declarantTracfin">
                    {{ checkIsNull(declarantTracfin.user.personne_physique.civilite, false, '[Civilité]') + ' ' +checkIsNull(declarantTracfin.user.personne_physique.nom, false, '[Nom]') + ' ' + checkIsNull(declarantTracfin.user.personne_physique.prenom, false, '[Prénom]')  }}
                    <br>
                    {{ checkIsNull(declarantTracfin.user.personne_physique.moyen_contact.tel, false, '[Téléphone]')  }}
                    <br>
                    {{ checkIsNull(declarantTracfin.user.personne_physique.moyen_contact.email, false, '[Mail]')  }}
                  </div>
                  <div v-else v-html="placeHolderRubrique"></div>
                </b-col>
              </b-row>
              <b-row class="mb-2">
                <b-col md="3">
                  DPO :
                </b-col>
                <b-col md="9">
                  <div v-if="dpo">
                    {{ checkIsNull(dpo.user.personne_physique.civilite, false, '[Civilité]') + ' ' +checkIsNull(dpo.user.personne_physique.nom, false, '[Nom]') + ' ' + checkIsNull(dpo.user.personne_physique.prenom, false, '[Prénom]')  }}
                    <br>
                    {{ checkIsNull(dpo.user.personne_physique.moyen_contact.tel, false, '[Téléphone]')  }}
                    <br>
                    {{ checkIsNull(dpo.user.personne_physique.moyen_contact.email, false, '[Mail]')  }}
                  </div>
                  <div v-else v-html="placeHolderRubrique"></div>
                </b-col>
              </b-row>
             <!-- <b-list-group>
                 <b-card class="mb-0">
                  <b-card-header class="p-0 pb-2">
                      <h3 class="mb">Dirigeant</h3>
                  </b-card-header>
                  <b-card-text>
                    <b-row>
                      <b-col sm="12" md="12">
                        <b-row v-if="interlocuteurs.dirigeant">
                          <b-col cols="12" class="pb-2">
                            {{ $_.get(interlocuteurs.interlocuteur.user.courtier, 'nom_dirigeant', null) + ' ' + $_.get(interlocuteurs.interlocuteur.user.courtier[0], 'prenom_dirigeant', null)  }}
                          </b-col>
                          <b-col cols="4" class="pb-2" v-show="false">
                            {{ $_.get(interlocuteurs.dirigeant.user.personne_physique.moyen_contact, 'tel', null)  }}
                          </b-col>
                          <b-col cols="8" class="pb-2" v-show="false">
                            {{ $_.get(interlocuteurs.dirigeant.user.personne_physique.moyen_contact, 'email', null)  }}
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card-text>
                </b-card>
                <b-card >-->
              <!-- <b-card-header class="p-0 pb-2">
                      <h3 class="mb">Interlocuteur du cabinet</h3>
                  </b-card-header>
                  <b-card-text>

                  </b-card-text>
                </b-card>
              </b-list-group>-->
            </b-card-text>
          </b-card>

        <b-card bg-variant="transparent" body-class="p-2" border-variant="primary" header="Actionnariat"
                header-bg-variant="primary" header-class="py-0 px-1 font-weight-bolder" header-text-variant="white" v-show="false"
        >
          <template #header>
            <h6 class="mb-0 font-weight-bolder text-white">Actionnariat</h6>
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="btn-icon rounded-circle text-white"
                      variant="flat-success"
            >
              <feather-icon icon="Edit3Icon" size="16"/>
            </b-button>
          </template>
          <b-card-text>
            <vue-good-table
                :columns="columnsActionnariat"
                :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 5,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => `sss`
              }"
                :rows="rowsActionnariat"
                :rtl="direction"
                style-class="vgt-table condensed"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <span v-if="props.column.field === 'actions'">
                  <span>
                    <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">
                      <template v-slot:button-content>
                        <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
                      </template>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="Edit2Icon"/>
                        <span>Éditer</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="PaperclipIcon"/>
                        <span>Joindre</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="TrashIcon"/>
                        <span>Supprimer</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col md="6" sm="12">

        <b-card bg-variant="transparent" body-class="p-2" border-variant="primary" header="Finance"
                header-bg-variant="primary" header-class="py-0 px-1 font-weight-bolder" header-text-variant="white" v-show="false"
        >
          <template #header>
            <h6 class="mb-0 font-weight-bolder text-white">Finance</h6>
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="btn-icon rounded-circle text-white"
                      variant="flat-success"
            >
              <feather-icon icon="Edit3Icon" size="16"/>
            </b-button>
          </template>
          <b-card-text>
            <vue-good-table
                :columns="columnsFinance"
                :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 5,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => ``
              }"
                :rows="rowsFinance"
                :rtl="direction"
                style-class="vgt-table condensed"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <span v-if="props.column.field === 'actions'">
                  <span>
                    <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">
                      <template v-slot:button-content>
                        <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
                      </template>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="Edit2Icon"/>
                        <span>Éditer</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="PaperclipIcon"/>
                        <span>Joindre</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="TrashIcon"/>
                        <span>Supprimer</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
          </b-card-text>
        </b-card>

        <b-card bg-variant="transparent" body-class="p-2" border-variant="primary" header="Cabinet lié"
                header-bg-variant="primary" header-class="py-0 px-1 font-weight-bolder" header-text-variant="white" v-show="false"
        >
          <template #header>
            <h6 class="mb-0 font-weight-bolder text-white">Cabinet lié</h6>
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="btn-icon rounded-circle text-white"
                      variant="flat-success"
            >
              <feather-icon icon="Edit3Icon" size="16"/>
            </b-button>
          </template>
          <b-card-text>
            <vue-good-table
                :columns="columnsCabinetLie"
                :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 5,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => ``
              }"
                :rows="rowsCabinetLie"
                :rtl="direction"
                style-class="vgt-table condensed"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <span v-if="props.column.field === 'actions'">
                  <span>
                    <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">
                      <template v-slot:button-content>
                        <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
                      </template>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="Edit2Icon"/>
                        <span>Éditer</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="PaperclipIcon"/>
                        <span>Joindre</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="TrashIcon"/>
                        <span>Supprimer</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
          </b-card-text>
        </b-card>

        <b-card bg-variant="transparent" body-class="p-2" border-variant="primary" header="Activité" v-show="false"
                header-bg-variant="primary" header-class="py-0 px-1 font-weight-bolder" header-text-variant="white" class="h-100"
        >
          <template #header>
            <h6 class="mb-0 font-weight-bolder text-white">Votre activité</h6>
            <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" class="btn-icon rounded-circle text-white"
                      variant="flat-success"
            >
              <feather-icon icon="Edit3Icon" size="16"/>
            </b-button>
          </template>
          <b-card-text>
            <vue-good-table
                :columns="columnsActivite"
                :pagination-options="{
                enabled: true,
                mode: 'records',
                perPage: 5,
                rowsPerPageLabel: '',
                dropdownAllowAll: false,
                perPageDropdownEnabled: false,
                nextLabel: 'Suivant',
                prevLabel: 'Précédent',
                ofLabel: 'de',
                infoFn: params => ``
              }"
                :rows="rowsActivite"
                :rtl="direction"
                style-class="vgt-table condensed"
            >
              <template slot="table-row" slot-scope="props">
                <!-- Column: Action -->
                <span v-if="props.column.field === 'actions'">
                  <span>
                    <b-dropdown no-caret toggle-class="text-decoration-none" variant="link">
                      <template v-slot:button-content>
                        <feather-icon class="text-body align-middle mr-25" icon="MoreVerticalIcon" size="16"/>
                      </template>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="Edit2Icon"/>
                        <span>Éditer</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="PaperclipIcon"/>
                        <span>Joindre</span>
                      </b-dropdown-item>
                      <b-dropdown-item>
                        <feather-icon class="mr-50" icon="TrashIcon"/>
                        <span>Supprimer</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </span>
                </span>
              </template>
              <div slot="emptystate" class="text-center align-middle">
                <span>Aucune donn&eacute;e disponible dans le tableau</span>
              </div>
            </vue-good-table>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BCardHeader,
  BCol,
  BDropdown,
  BDropdownItem,
  BListGroup,
  BListGroupItem,
  BRow,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store'
import UpdateCabinetJuridique from './UpdateCabinetJuridique.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BButton,
    BDropdown,
    BDropdownItem,
    BCardHeader,
    // VGT
    VueGoodTable,

    // UI
    UpdateCabinetJuridique
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    courtierData: {
      type: Object,
      default: null,
      required: true
    },
    interlocuteurs: {
      type: Object,
      default: null,
      required: true,
    },
    correspondantTracfin: {
      type: Object,
      default: null,
      required: true,
    },
    declarantTracfin: {
      type: Object,
      default: null,
      required: true,
    },
    dpo: {
      type: Object,
      default: null,
      required: true,
    }
  },
  data() {
    return {
      placeHolderRubrique: '[Civilité] [Prénom] [Nom] <br> [Téléphone] <br> [Mail]',
      columnsFinance: [
        {
          field: 'date_debut',
          label: 'Début',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          field: 'date_fin',
          label: 'Fin',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          field: 'ca',
          label: 'Chiffres d\'affaire',
          sortable: false,
          type: 'decimal',
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          field: 'jusif',
          label: 'Justificatif',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          field: 'effectif',
          label: 'Effectif',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          field: 'actions',
          label: 'Actions',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      columnsActionnariat: [
        {
          label: 'Nom',
          field: 'nom_prenom',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: '% Capital',
          field: 'part',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      columnsCabinetLie: [
        {
          label: 'Nom',
          field: 'nom',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Lien',
          field: 'lien',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Début',
          field: 'date_debut',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Fin',
          field: 'date_fin',
          type: 'date',
          dateInputFormat: 'dd/MM/yyyy',
          dateOutputFormat: 'dd/MM/yyyy',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Statut',
          field: 'statut',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      columnsActivite: [
        {
          label: 'Risque',
          field: 'risque',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: '% CA',
          field: 'ca',
          type: 'decimal',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Nombre AFN',
          field: 'nbafn_an',
          type: 'decimal',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Produit',
          field: 'prod',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          thClass: 'text-center align-middle bg-primary text-white unbreaking h6',
          tdClass: 'text-center m-0 p-1 align-middle h6'
        }
      ],
      rowsFinance: [],
      rowsActionnariat: [],
      rowsCabinetLie: [],
      rowsActivite: []
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  methods: {
    RecordCourtierJuridiqueReturned(message) {
      this.$emit('record-courtier-juridique-returned', message)
    },
    get(object, path, defaultValue) {
      var result = object == null ? undefined : baseGet(object, path);
      return result === undefined ? defaultValue : result;
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
