<template>
  <div>
    <!-- Card global infos -->
    <b-sidebar
    id="paymentSidebar"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    ref="paymentSidebar" 
    right
    >
        <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
            <h5 class="mb-0">
            Génération du Mandat SEPA
            </h5>

            <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
            />

        </div>

        <!-- BODY -->
        <validation-observer
            #default="{ handleSubmit }"
            ref="refFormObserver"
        >
            <!-- Form -->
            <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
            >

            <b-overlay :show="showLoading" no-wrap />
            <b-row>
            <b-col md="12">
                <b-form-group label="Mode de paiement" label-for="mode_de_paiement">
                <b-form-select
                    v-model="compteBancaire.mode_de_paiement"
                    id="mode_de_paiement"
                    disabled
                >
                    <b-form-select-option value="PREV" selected
                    >Prélèvement</b-form-select-option
                    >
                </b-form-select>
                </b-form-group>
            </b-col>
            <b-col md="12">
                <b-form-group label-for="periodicite" label="Périodicité">
                    <b-form-select
                    v-model="compteBancaire.periodicite"
                    id="periodicite"
                    disabled
                    >
                    <b-form-select-option value="ANNUEL" selected
                        >Annuelle</b-form-select-option
                    >
                    </b-form-select>
                </b-form-group>
            </b-col>
            </b-row>
            <b-row>
            <b-col md="12">
                <b-form-group label-for="nom_titulaire" label="Nom du Titulaire">
                <validation-provider
                    #default="{ errors }"
                    name="Nom du Titulaire"
                    rules="required"
                >
                    <b-form-input
                    id="nom_titulaire"
                    placeholder="Nom du Titulaire"
                    v-model="compteBancaire.nom_titulaire"
                    :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                </validation-provider>
                </b-form-group>
                <h4>Information d’adresse</h4>
                <b-form-group
                  label="N° et libellé de la voie*"
                  label-for="prospect_morale_libelle"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="n° et libellé de la voie"
                      rules="required"
                    >
                    <b-form-input
                      id="prospect_morale_libelle"
                      trim
                      placeholder="N° et libellé de la voie"
                      v-model="courtierData.libelle"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="Complément d’adresse"
                  label-for="prospect_morale_complement_adresse"
                >
                  <!-- <validation-provider
                      #default="{ errors }"
                      name="Complément d’adresse"
                      rules="required"
                    > -->
                    <b-form-input
                      id="prospect_morale_libelle"
                      trim
                      placeholder="Complément d’adresse (Bâtiment, Appartement…)"
                      v-model="courtierData.complement_adresse"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider> -->
                </b-form-group>
                <b-form-group
                  label="Lieu-dit ou BP"
                  label-for="prospect_morale_lieu_dit_ou_bp"
                >
                <!-- <validation-provider
                      #default="{ errors }"
                      name="Lieu-dit ou BP"
                      rules="required"
                    > -->
                    <b-form-input
                      id="prospect_morale_lieu_dit_ou_bp"
                      trim
                      placeholder="Lieu-dit ou BP"
                      v-model="courtierData.lieu_dit_ou_bp"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider> -->
                </b-form-group>

                <b-form-group
                  label="Code postal*"
                  label-for="prospect_morale_code_postal"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="code postal"
                      rules="required|min:5"
                    >
                    <b-form-input
                      id="prospect_morale_code_postal"
                      trim
                      placeholder="Code postal"
                      @keyup="cpostalKeyupPM()"
                      v-model="courtierData.code_postal"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                    label="Ville*"
                    label-for="prospect_morale_ville_id"
                  >
                  <validation-provider
                      #default="{ errors }"
                      name="ville"
                      rules="required"
                    >
                    <b-form-select
                    v-model="courtierData.ville_id"
                    id="prospect_morale_ville_id"
                    :state="errors.length > 0 ? false : null"
                    >
                      <b-form-select-option :value="null" disabled>Choisissez</b-form-select-option>
                      <b-form-select-option v-for="(val, value) in villesPM" :key="value" :value="val.id">{{val.ville}}</b-form-select-option>
                    </b-form-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
            </b-col>
            </b-row>
            <b-row>
            <b-col md="12">
                <h4>Informations de paiements</h4>
                <b-form-group label-for="iban" label="IBAN">
                <validation-provider
                    #default="{ errors }"
                    name="IBAN"
                    rules="required"
                >
                    <b-form-input
                    id="iban"
                    placeholder="IBAN"
                    v-model="compteBancaire.iban"
                    :state="
                        errors.length > 0 ||
                        (!isValidIban && compteBancaire.iban != null)
                        ? false
                        : null
                    "
                    />
                    <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                    <small
                    class="text-danger text-lowercase"
                    v-if="!isValidIban && compteBancaire.iban != null && compteBancaire.iban != ''"
                    >veuillez saisir un iban valide</small
                    >
                </validation-provider>
                </b-form-group>
            </b-col>

            <b-col md="12">
                <b-form-group label-for="bic" label="BIC">
                <validation-provider
                    #default="{ errors }"
                    name="BIC"
                    rules="required"
                >
                    <b-form-input
                    id="bic"
                    placeholder="BIC"
                    v-model="compteBancaire.bic"
                    :state="
                        errors.length > 0 ||
                        (!isValidBic && compteBancaire.bic != null)
                        ? false
                        : null
                    "
                    />
                    <small class="text-danger text-lowercase">{{ errors[0] }}</small>
                    <small
                    class="text-danger text-lowercase"
                    v-if="!isValidBic && compteBancaire.bic != null && compteBancaire.bic != ''"
                    >veuillez saisir un bic valide</small
                    >
                </validation-provider>
                </b-form-group>
            </b-col>
            </b-row>


            <!-- Form Actions -->
            <div class="d-flex justify-content-between mt-2">
                <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
                :disabled="btn_disabled"
                >
                Enregistrer
                </b-button>
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                class="mr-2"
                variant="outline-secondary"
                @click="hide"
                >
                Annuler
                </b-button>
                <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                class="border-0"
                variant="outline-danger"
                >
                <feather-icon icon="FileTextIcon" size="16"/>
                </b-button>
                
            </div>

            </b-form>
        </validation-observer>
        </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  VBToggle,
  BRow,
  BCol,
  BButton,
  BCard,
  BCardText,
  BCardHeader,
  BMedia,
  BImg,
  BOverlay,
  BSidebar,
  BForm,
  BFormInput,
  BFormGroup,
  BFormSelect,
  BFormSelectOption
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardText,
    BCardHeader,
    BMedia,
    BImg,
    BOverlay,
    BSidebar,
    BForm,
    BFormInput,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider, 
    ValidationObserver
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },
  props: ['courtierData'],
  data() {
    return {
      showLoading: false,
      isValidIban: false,
      isValidBic: false,
      villesPM: [],
      verified: false,
      compteBancaire:{
          mode_de_paiement:'PREV',
          periodicite:'ANNUEL',
          denomination_commercial:null,
          nom_titulaire:null,
          iban:null,
          bic:null
      },
      dataCourtier:null,
      btn_disabled:false
    };
  },
  watch: {
      'compteBancaire.iban': {
      immediate:true,
      handler(val){
          if(val && val.length > 12){
              this.checkiban(val)
          }
      }
    },
    'compteBancaire.bic': {
      immediate:true,
      handler(val){
        if(val && val.length > 5){
            this.checkbic(val)
        }
      }
    },
    'courtierData':{ // watch it
        immediate:true,
        handler(val){
            this.dataCourtier = val
            if(this.dataCourtier.compte_bancaire.mandat_sepa){
              if(this.dataCourtier.compte_bancaire.mandat_sepa.statut == "signe"){
                this.verified = true
              }
              else
              {
                this.verified = false
              }
            }
            this.compteBancaire.nom_titulaire = this.dataCourtier.nom_courtier
            this.compteBancaire.iban = this.dataCourtier.compte_bancaire.iban
            this.compteBancaire.bic = this.dataCourtier.compte_bancaire.bic
            this.cpostalKeyupPM()
        }
    },
  },
  methods: {
      checkiban(iban){
        this.$http
        .post(`checkiban`, {
          iban: this.compteBancaire.iban
        })
        .then((r) => {
          this.isValidIban = r.data.response;
        }).catch(err => {
          console.log(err)
        })
    },
    async cpostalKeyupPM() {
      let cp = this.dataCourtier.code_postal
      try {
        if (cp.length == 5) {
          try {
            this.villesPM = (await this.$http.get(`helpers/${cp}/villes`)).data
            if (this.dataCourtier.ville_id == null) {
              this.dataCourtier.ville_id = this.villesPM[0].id
            }
          } catch (e) {
            console.log(e)
          }
        } else {
          this.villesPM = []
          this.dataCourtier.ville = null
        }
      } catch (e) {}
    },
    checkbic(bic){
        this.$http
        .post(`checkbic`, {
          bic: this.compteBancaire.bic
        })
        .then((r) => {
          this.isValidBic = r.data.response;
        }).catch(err => {
          console.log(err)
        })
    },
    onSubmit(){
      this.$refs.refFormObserver.validate()
            .then(success => {
              this.showLoading = true;
              this.btn_disabled = true;
              this.$http.post(`courtier/paymentInformations`, {
              paiement: this.compteBancaire,
              courtier: this.dataCourtier,
              verified: this.verified
              })
              .then((r) => {
                // console.log(r.data.response);
                this.messageToast(r.data.message, 'Succès', 'success', 'InfoIcon')
                this.$emit('set-statut-mandat-sepa');
                this.$refs.paymentSidebar.hide();
                this.resetForm();
                this.$refs.refFormObserver.reset()
                this.btn_disabled = false;
                this.showLoading = false;
              }).catch(err => {
                console.log(err)
                this.btn_disabled = false;
                this.showLoading = false;
              })
            })
    },
    resetForm(){
        Object.keys(this.compteBancaire).forEach(function(obj,key) {
            obj = null
        });

        Object.keys(this.courtierData).forEach(function(obj,key) {
            obj = null
        });

    }
  }
};
</script>

<style></style>
