<template>
  <div>
    <b-sidebar id="sidebar-cabinet" ref="sideBarCabinet" backdrop bg-variant="white" no-header right
               shadow sidebar-class="sidebar-lg"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-uppercase text-primary font-weight-bolder">Modifier courtier</h5>

          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
        </div>
        <validation-observer ref="cabinetComposeRules">
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
            <!-- clients list -->
            <h4>INFORMATION</h4>

            <b-form-group label="Dénomination commerciale" label-for="denomination_commercial">
              <b-form-input id="denomination_commercial" v-model="composeData.personneMorale.denomination_commercial"

                            placeholder="Dénomination commerciale"
              />
            </b-form-group>

            <b-form-group label="Raison sociale" label-for="raison_sociale">
              <b-form-input id="raison_sociale" v-model="composeData.personneMorale.raison_sociale"
                            placeholder="Raison sociale"
              />
            </b-form-group>

            <h4>COORDONNÉES</h4>
            <b-form-group label="N° et libellé de la voie" label-for="nEtLibelleVoie">
              <b-form-input id="nEtLibelleVoie" v-model="composeData.moyenContact.adresse"
                            placeholder="N° et libellé de la voie"
              />
            </b-form-group>

            <b-form-group label="Complément d'adresse (Société, Bâtiment, N°App, Lieu dit, etc.)"
                          label-for="complementAdresse"
            >
              <b-form-input id="complementAdresse" v-model="composeData.moyenContact.complement_adresse"
                            placeholder="Complément d'adresse (Société, Bâtiment, N°App, Lieu dit, etc.)"
              />
            </b-form-group>

            <b-form-group label="Lieu-dit ou BP" label-for="lieuDitOuBp">
              <b-form-input id="lieuDitOuBp" v-model="composeData.moyenContact.lieu_dit_ou_bp"
                            placeholder="Lieu-dit ou BP"
              />
            </b-form-group>

            <b-form-group label="Code Postal" label-for="codePostal">
              <b-form-input id="codePostal" v-model="composeData.moyenContact.code_postal"
                            placeholder="Code Postal"
              />
            </b-form-group>

            <b-form-group label="Ville" label-for="ville">
              <b-form-select id="ville" v-model="composeData.moyenContact.ville_id" :options="villes"
                             text-field="ville" value-field="id"
              >
                <template #first>
                  <b-form-select-option :value="null">-- Choisissez --</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>

            <h4>CONTACT</h4>
            <b-form-group label="Numéro de téléphone" label-for="phone">
              <validation-provider #default="{ errors }"
                                   :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                                   name="numéro de téléphone"
                                   rules="required"
              >
                <cleave id="phone" v-model="composeData.moyenContact.tel" :options="options.phone" :raw="false"
                        :state="errors.length > 0 ? false : null" class="form-control"
                        placeholder="ex: 06 00 00 00 00"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Numéro de téléphone fixe" label-for="phone_fixe">
              <validation-provider #default="{ errors }"
                                   :rules="{ regex: /^([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1} ([0-9])\d{1}$/ }"
                                   name="Numéro de téléphone fixe"
              >
                <cleave id="phone_fixe" v-model="composeData.moyenContact.tel_secondaire" :options="options.phone"
                        :raw="false" :state="errors.length > 0 ? false : null" class="form-control"
                        placeholder="ex: 06 00 00 00 00"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">{{
                    errors[0]
                  }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="site web" label-for="site web">
              <validation-provider #default="{ errors }" name="site web" rules="url">
                <b-form-input id="site web" v-model="composeData.moyenContact.site_web"
                              :state="errors.length > 0 ? false : null" placeholder="www.bubble-in.com"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false : null">Le champ site web est invalide
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>

            <b-form-group label="Bio (annuaire)" label-for="bio">
                <b-form-input id="bio" v-model="composeData.personneMorale.bio"
                               placeholder="Bio"
                />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" class="mr-2" variant="primary"
                        @click="recordCourtier"
              > Enregistrer
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormSelectOption,
  BSidebar,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, url } from '@validations'
import Cleave from 'vue-cleave-component'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BSidebar,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    Cleave,
    BFormSelect,
    BFormSelectOption,

    // Form Validation
    ValidationProvider,
    ValidationObserver,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple
  },
  props: {
    personneMorale: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      required,
      url,
      options: {
        phone: {
          blocks: [2, 2, 2, 2, 2],
          uppercase: true
        }
      },
      villes: [],
      isUpdateAction: false,
      villeOptions: [],
      composeData: {
        idMoyenContact: null,
        idpersonneMorale: null,
        personneMorale: {
          denomination_commercial: null,
          raison_sociale: null,
          bio: null
        },
        moyenContact: {
          adresse: null,
          complement_adresse: null,
          lieu_dit_ou_bp: null,
          code_postal: null,
          ville_id: null,

          tel: null,
          tel_secondaire: null,
          site_web: null
        }
      }
    }
  },
  watch: {
    'composeData.moyenContact.code_postal': {
      immediate: true,
      handler(val) {
        this.GetVille(val)
      }
    },
    personneMorale: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.composeData.idpersonneMorale = val.id
          this.composeData.personneMorale.denomination_commercial = val.denomination_commercial
          this.composeData.personneMorale.raison_sociale = val.raison_sociale
          this.composeData.personneMorale.bio = val.bio
          this.composeData.idMoyenContact = val.moyen_contact.id
          this.composeData.moyenContact.adresse = val.moyen_contact.adresse
          this.composeData.moyenContact.lieu_dit_ou_bp = val.moyen_contact.lieu_dit_ou_bp
          this.composeData.moyenContact.code_postal = val.moyen_contact.code_postal
          this.composeData.moyenContact.ville_id = val.moyen_contact.ville_id
          this.composeData.moyenContact.complement_adresse = val.moyen_contact.complement_adresse
          this.composeData.moyenContact.tel = val.moyen_contact.tel
          this.composeData.moyenContact.tel_secondaire = val.moyen_contact.tel_secondaire
          this.composeData.moyenContact.site_web = val.moyen_contact.site_web
        }
      }
    }
  },

  methods: {
    // Methode additional
    hideSideBare() {
      this.$refs.sideBarCabinet.hide()
    },
    GetVille(code) {
      if (code && code.length === 5) {
        this.$http
            .get(`/helpers/${code}/villes`)
            .then(res => {
              if (res.data?.length > 0) {
                this.villes = [...res.data]
                this.composeData.moyenContact.ville_id = this.villes[0].id
              }
            })
            .catch(err => {
              console.log(err)
            })
      } else {
        this.villes = []
      }
    },

    messageToast(text, title, variant) {
      this.$toast({
        component: ToastificationContentVue,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant
        }
      })
    },
    // record Data courtier
    recordCourtier() {
      this.$refs.cabinetComposeRules.validate()
          .then(success => {
            if (success) {
              this.$http
                  .post('/courtier/updataDataCourtierMoyenContact', this.composeData)
                  .then(res => {
                    if (res.data.success) {
                      const message = 'Les modifications ont été faites avec succès.'
                      this.$emit('record-courtier-returned', message)
                      this.$refs.cabinetComposeRules.reset()
                      this.hideSideBare()
                    }
                  })
                  .catch(err => {
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'warning')
                  })
            }
          })
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
