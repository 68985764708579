<template>
  <div>
    <!-- table -->

    <b-overlay :show="showLoadingo" no-wrap />
    <vue-good-table
      :columns="columnsCodesAssureurs"
      :rows="rowsMembres"
      :rtl="direction"
      style-class="vgt-table condensed"
      :pagination-options="{
        enabled: true,
        mode: 'records',
        perPage: pageLength,
        rowsPerPageLabel: '',
        dropdownAllowAll: false,
        perPageDropdownEnabled: false,
        nextLabel: 'Suivant',
        prevLabel: 'Précédent',
        ofLabel: 'de',
        infoFn: params => ``
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field == 'statut'">
            <template v-if="props.row.utilisateur == 'Oui'">
                <b-badge variant="light-info">
                    {{ props.row.utilisateur }}
                </b-badge>
            </template>
            <template v-else>
                <b-badge variant="light-warning">
                    {{ props.row.utilisateur }}
                </b-badge>
            </template>
        </span>
        <span v-if="(props.column.field == 'suspend' && props.row.utilisateur == 'Oui')">
            <template v-if="props.row.bloqued == 1">
              <b-badge variant="light-danger" v-b-tooltip.hover title="Compte bloqué">
                <feather-icon
                  class="text-body align-middle mr-25"
                  icon="AlertOctagonIcon"
                  size="16"
                />
              </b-badge>
            </template>
            <template v-else>
              <b-badge variant="light-success" v-b-tooltip.hover title="Compte actif">
                <feather-icon
                  class="text-body align-middle mr-25"
                  icon="AwardIcon"
                  size="16"
                />
              </b-badge>
            </template>
        </span>
        <span v-if="props.column.field == 'name'">
            {{ props.row.nom }} ({{ props.row.user_name }})
        </span>
        <span v-else-if="props.column.field === 'actions'">
          <span>
            <template v-if="props.row.utilisateur == 'Oui'">
            <b-dropdown
                dropright
                no-caret
                toggle-class="text-decoration-none p-0"
                variant="link"
            >
              <template v-slot:button-content>
                <feather-icon
                    class="text-body align-middle mr-25"
                    icon="MoreVerticalIcon"
                    size="16"
                />
              </template>
              <b-dropdown-item @click="sendPasswordReset(props.row.user_id)">
                <feather-icon
                    class="mr-50"
                    icon="RefreshCcwIcon"
                />
                <span>Réinitialiser le mot de passe</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="props.row.bloqued == 1" @click="debloquerCompte(props.row.user_id)">
                <feather-icon
                    class="mr-50"
                    icon="ShieldOffIcon"
                />
                <span>Débloquer le compte</span>
              </b-dropdown-item>
            </b-dropdown>
            </template>
          </span>
        </span>
        <span v-else>
                {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <b-row class="mt-2 align-items-center">
              <b-col md="6" lg="5" xxl="3">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap ">
                    Affichage 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> de {{ props.total }} entrées </span>
                </div>
              </b-col>
          </b-row>
        <b-row class="mt-2 align-items-center">
          <b-col>
          </b-col>
          <b-col>
            <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" @input="value => props.pageChanged({ currentPage: value })">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </template>
      <div class="text-center align-middle" slot="emptystate">
        <span>Aucune donn&eacute;e disponible dans le tableau</span>
      </div>
    </vue-good-table>
  </div>
</template>

<script>
import { BPagination, BFormGroup, BFormSelect, BFormSelectOption, BRow, BCol, BOverlay,BLink,BDropdown,BDropdownItem,BBadge,VBTooltip} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import Ripple from 'vue-ripple-directive'
import store from '@/store/index'

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormSelect,
    BFormSelectOption,
    BRow,
    BCol,
    BOverlay,
    BLink,
    BDropdown,
    BDropdownItem,
    BBadge,
    VBTooltip
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      showLoadingo: false,
      isBoiteSync: false,
      currentUser: JSON.parse(localStorage.getItem('userData')),
      pageLength: 10,
      dir: false,
      options: [
        { value: null, text: 'Action de masse' },
        { value: 'autre', text: ' --- ' }
      ],
      selected: null,
      columnsCodesAssureurs: [
        { label: '#', field: 'suspend', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Nom', field: 'name', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Fonction', field: 'fonction', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Utilisateur', field: 'statut', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
        { label: 'Actions', field: 'actions', sortable: false, thClass: 'text-center align-middle bg-primary text-white unbreaking h6', tdClass: 'text-center m-0 p-1 align-middle h6' },
      ],
      rowsMembres: [],
      rowsMembresKeeped: [],
      searchTerm: null
    }
  },
  props: {
    courtierId: {
      type: Number,
      default: null,
      required: false
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    }
  },
  watch: {
    searchTerm: {
      handler() {
        this.filterDataByStatut()
      }
    }
  },
  created() {
    this.fetchMembres()
  },
  methods: {
    fetchMembres() {
      this.showLoadingo = true
      const courtierId = this.courtierId ? this.courtierId : this.currentUser.courtier_user[0].courtier_id
      this.$http
        .get('/courtier/getUsersByCourtier/'+courtierId)
        .then(res => {
          if (res.data.success) {
            this.rowsMembres = res.data.data
            this.rowsMembresKeeped = res.data.data
            this.showLoadingo = false
          } else {
            this.rowsMembres = []
            this.rowsMembresKeeped = []
            this.showLoadingo = false
          }
        })
        .catch(err => {
          this.rowsMembres = []
          this.rowsMembresKeeped = []
          this.showLoadingo = false
          console.error(err)
        })
    },
    sendPasswordReset(userId) {
      this.$swal({
        title: '',
        text: 'Etes-vous sûr de vouloir envoyer un e-mail de réinitialisation du mot de passe ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
          .then(result => {
            if (result.value) {
              this.showLoading = true
              this.$http
                  .put(`/collaborateur/ResetPasswordMembreCabinet/${userId}`)
                  .then(res => {
                    if (res.data.success) {
                      this.messageToast(res.data.message, 'Succès', 'success')
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                    console.error(err)
                  })
            }
          })
    },
    debloquerCompte(userId) {
      this.$swal({
        title: '',
        text: 'Etes-vous sûr de vouloir débloquer le compte ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      })
          .then(result => {
            if (result.value) {
              this.showLoading = true
              this.$http
                  .put(`/collaborateur/debloquecompte/${userId}`)
                  .then(res => {
                    if (res.data.success) {
                      this.messageToast(res.data.message, 'Succès', 'success')
                      this.showLoading = false
                    }
                  })
                  .catch(err => {
                    this.showLoading = false
                    this.messageToast(err.response.data.errors[0], 'Erreur', 'error')
                    console.error(err)
                  })
            }
          })
    },

  }
}
</script>
