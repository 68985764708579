<template>
  <b-card no-body>
    <b-tabs card  v-if="courtierData !== null">
      <b-tab title="Détails" active>
        <details-cabinet :courtier-data="courtierData"
                         :correspondant-tracfin="correspondantTracfin"
                         :declarant-tracfin="declarantTracfin"
                         :dpo="dpo"
                         :interlocuteurs="interlocuteurs"
                         @record-courtier-juridique-returned="RecordCourtierJuridiqueReturned" />
      </b-tab>
      <b-tab title="Codes assureurs">
        <codes-assureurs-cabinet :courtier-id="courtierData.courtier.id" @update-code-assureur="editCodeAssureur" :code-updated="codeUpdated" />
      </b-tab>
      <b-tab title="Membres du cabinet">
        <membres-cabinet :courtier-id="courtierData.courtier.id" />
      </b-tab>
      <b-tab title="Conformité">
        <conformite-cabinet :courtier-id="courtierData.courtier.id" />
      </b-tab>
      <b-tab title="Autres documents">
        <autres-documents :courtier-id="courtierData.courtier.id"
        />
      </b-tab>
      <b-tab :title="`Notes ${countnote > 0 ? '(' + countnote + ')' : ''}`">
          <note @count = "calculenote" :courtier-id="courtierData.courtier.id" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { BTabs, BTab, BCard } from 'bootstrap-vue'
import DetailsCabinet from './detail-cabinet/DetailsCabinet.vue'
import CodesAssureursCabinet from '@/views/outils/gestion-cabinet/codes-assureurs-cabinet/CodesAssureursCabinet.vue'
import MembresCabinet from './membres-cabinet/MembresCabinet.vue'
import ConformiteCabinet from './conformite-cabinet/ConformiteCabinet.vue'
import AutresDocuments from './autres-documents/AutresDocuments.vue'
import Note from './Note.vue'

export default {
  components: {
    // BTV
    BTabs,
    BTab,
    BCard,
    // UI
    DetailsCabinet,
    CodesAssureursCabinet,
    MembresCabinet,
    ConformiteCabinet,
    AutresDocuments,
    Note
  },
  props: {
    codeUpdated: {
      type: Boolean,
      default: false,
      required: false
    },
    courtierData: {
      type: Object,
      default: null,
      required: true
    },
    interlocuteurs: {
      type: Object,
      default: null,
      required: true,
    },
    correspondantTracfin: {
      type: Object,
      default: null,
      required: true,
    },
    declarantTracfin: {
      type: Object,
      default: null,
      required: true,
    },
    dpo: {
      type: Object,
      default: null,
      required: true,
    }
  },
  data() {
    return {
      countnote: 0,
    }
  },
  methods: {
    RecordCourtierJuridiqueReturned(message) {
      this.$emit('record-courtier-juridique-returned',message)
    },
    calculenote(total) {
      console.log(total)
      this.countnote = total
      
    },
    editCodeAssureur(data) {
      if(data) {
        this.$emit('data-code-assureur', data)
      }
    },
  }
}
</script>
